import axios from 'axios';
import { API_ENDPOINT } from '../config';

export default axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
});

export const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers:{
        "Content-Type": "application/json",
    },
});
import { useQuery } from "@tanstack/react-query";

import { useState } from 'react'
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";
import { generateURL } from "../utilities";

function useRead({url,initialData,method, onSuccess = () => {},onError = () => {}}) {
  const [paramsObject, setGetListParams] = useState(initialData)
  const data = useQuery([url, paramsObject], () => getUsers(paramsObject), {
    refetchOnWindowFocus: false,
    retry:false,
    onSuccess: (data) => {
      onSuccess(data)
    },onError:(err) =>{
      onError(err)
    }
  })
  const getUsers = async (paramsObject) => {
    let TOKEN = sessionStorage.getItem(TOKEN_KEY);
    let fd = new FormData()
    for (var key in paramsObject) {
      fd.append(key, paramsObject[key]);
    }
    const response = await axios({
      method: method ? method : "POST",
      data: fd,
      url: `/${url}${paramsObject ? generateURL(paramsObject) : ''}`,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
  }
  return { paramsObject, setGetListParams, data }

}

export default useRead
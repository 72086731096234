import axios from "../../apis/axios";

let TOKEN = sessionStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

function useDownload({ url = 'product_excel_templte_download', onSuccess, onError }) {
    const downloadFile = async (data) => {
       
        let fd = new FormData();
        fd.append('id', data); // Append the id properly as a single value
        // Append other data if needed
        // for (var key in data) {
        //     if (key !== 'id') {
        //         fd.append(key, data[key]);
        //     }
        // }
        try {
            const response = await axios({
                method: 'POST',
                url: url,
                data:fd, // Data to be sent with the POST request
                responseType: 'blob', // Important for downloading files
                headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '' }
            });

            // Create a URL for the blob and trigger a download
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'template.xlsx'); // or whatever file name you want
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl);

            onSuccess?.(response.data);
        } catch (error) {
            onError?.(error);
        }
    };

    return { downloadFile };
}

export default useDownload;

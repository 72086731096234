import { Button, Dialog, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useRef, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addCategory } from "../../apis/category";
import Spinner from "../../components/spinner";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import useUploadMedia from "../../hooks/file/useUploadMedia";

const fileTypes = ["JPG", "PNG", "JPEG"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddCategory({ open, onClose }) {
  const queryClient = useQueryClient();
  const initialstate = {
    title: "",
    subtitle: "",
    category_content: "",
    name: "",
    thumbnail: {
      image_id: "",
      image_alt: "",
    },
  
  };
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [categoryData, setCategoryData] = useState(initialstate);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleChange = (file, name, previewName) => {
    let data = {
      ...categoryData,
      [name]: file,
      [previewName]: URL.createObjectURL(file),
    };
    setCategoryData(data);
  };
  const handleInputData = (e) => {
    let value = e.target.value;
    let data = {
      ...categoryData,
      [e.target.name]: value,
    };
    setCategoryData(data);
  };

  const handlereupload = (name, previewName) => {
    let data = {
      ...categoryData,
      [name]: null,
      [previewName]: null,
    };
    setCategoryData(data);
  };

  const handleCloseAndReset = () => {
    onClose();
    setIsSuccess(false);
    setIsError(false);
    setCategoryData(initialstate);
  };
//   const clearData = () => {
//     setCategoryData(initialstate)
//     onClose()
// }
const clearData = () => {
    setCategoryData(initialstate)
    setImagePreview(null); // Reset image preview
    onClose()
}
  const setCategoryDataToServer = useMutation((data) => addCategory(data), {
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries("categoryList");
        setIsSuccess(true);
        setTimeout(() => {
            onClose();
        }, 2000);
      }
    },
    onError: () => {
      setIsError(true);
    },
  });
  const handleAddCategory = (e) => {
    e.preventDefault();
    console.log(categoryData);
    
    setCategoryDataToServer.mutate(categoryData);
  };

  const closeErrorMessage = () => {
    setIsError(false);
  };

  useEffect(() => {
    isError && setTimeout(() => setIsError(false), [1500]);
  }, [isError]);

  const handleOpenFileUploadDialog = () => {
    fileInputRef.current.click();
  };
  const { upload } = useUploadMedia({
    url: "uploadImage",
    onSuccess: (res) => {
      const { image_url, image_id } = res?.data;
      let formDataCopy = categoryData;
    //   formDataCopy.thumbnail = {};
      formDataCopy["thumbnail"].image_id = image_id;
    //   console.log("checkpoint");

      setImagePreview(image_url);
      setCategoryData({ ...categoryData });
    },
  });
  // let uploadScreen;
  const handleUploadFile = (e) => {
    let thumbnail = e.target?.files[0];
    let data = {
      screen: "category",
      thumbnail: [thumbnail],
    };
    upload.mutate(data);
  };

  return (
    <>
      <Dialog
        fullScreen
        maxWidth={"md"}
        open={open}
        onClose={clearData}
        TransitionComponent={Transition}
      >
        <div className="container_xxl px-4">
          <div className="flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white">
            <div>
              <IconButton onClick={clearData}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className="heading">Add Category</h1>
            </div>
          </div>
          <div className="form-body py-8">
            <form className=" space-y-4" onSubmit={handleAddCategory}>
              <div className=" space-y-7">
                <div>
                  <label htmlFor="Product Name" className="label">
                    Title for Category <small className="text-red-700">*</small>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Title"
                    name="title"
                    className="input"
                    value={categoryData.title}
                    onChange={handleInputData}
                  />
                </div>
                <div>
                  <label htmlFor="Product Name" className="label">
                    Subtitle for Category{" "}
                    <small className="text-red-700">*</small>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Sub-Title"
                    name="subtitle"
                    className="input"
                    value={categoryData.subtitle}
                    onChange={handleInputData}
                  />
                </div>
                <div>
                  <label htmlFor="Product Name" className="label">
                    Description for Category{" "}
                    <small className="text-red-700">*</small>
                  </label>
                  <textarea
                    required
                    name="category_content"
                    value={categoryData.category_content}
                    onChange={handleInputData}
                    className="input min-h-[6rem]"
                    placeholder="Enter description"
                  ></textarea>
                  <small className="text-gray-600 capitalize font-semibold">
                    Note: press enter to break the line
                  </small>
                </div>
                <div>
                  <label htmlFor="Product Name" className="label">
                  Name
                    <small className="text-red-700">*</small>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    className="input"
                    value={categoryData.name}
                    onChange={handleInputData}
                  />
                </div>
                {/* <div>
                                    <label htmlFor="Product Name" className='label'>Category Banner Image</label> <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    {!categoryData.category_banner_image ? <FileUploader handleChange={(file) => handleChange(file,'category_banner_image','category_banner_image_preview')} name="image" types={fileTypes} classes="file-uploader" hoverTitle='Drop here' /> :
                                        <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={categoryData.category_banner_image_preview} alt="preview" />
                                                <Button variant="outlined" onClick={() => handlereupload('category_banner_image','category_banner_image_preview')}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Category Showcase Image</label> <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    {!categoryData.category_showcase_image ? <FileUploader handleChange={(file) => handleChange(file,'category_showcase_image','category_showcase_image_preview')} name="image" types={fileTypes} classes="file-uploader" hoverTitle='Drop here' /> :
                                        <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={categoryData.category_showcase_image_preview} alt="preview" />
                                                <Button variant="outlined" onClick={() => handlereupload('category_showcase_image','category_showcase_image_preview')}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div> */}
                <div className="">
                  <label htmlFor="" className="label">
                    Category Image <small className="text-red-700">*</small>
                  </label>
                  {upload.isLoading ? (
                    <>
                      <Spinner />
                    </>
                  ) : (
                    <>
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="blog image"
                          className="object-contain w-48 h-48 my-4"
                        />
                      )}
                    </>
                  )}

                  <div className="mt-5">
                    <Button
                      variant="contained"
                      color="success"
                      className="primaryBtn-contained"
                      onClick={handleOpenFileUploadDialog}
                    >
                      {imagePreview
                        ? "Update Category Image "
                        : " Add Category Image"}
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      onChange={(e) => handleUploadFile(e)}
                    />
                  </div>
                </div>
                {/* <div>
                                    <label htmlFor="Product Name" className='label'>Category Image</label> <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    {!categoryData.image ? <FileUploader handleChange={(file) => handleChange(file,'image','image_preview')} name="image" types={fileTypes} classes="file-uploader" hoverTitle='Drop here' /> :
                                        <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={categoryData.image_preview} alt="preview" />
                                                <Button variant="outlined" onClick={() => handlereupload('image','image_preview')}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div> */}
              </div>
              {isError && (
                <Alert
                  onClose={closeErrorMessage}
                  className="mb-4"
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {setCategoryDataToServer?.error?.message}, Please try again
                  later
                </Alert>
              )}
              {isSuccess && (
                <Alert
                  onClose={closeErrorMessage}
                  className="mb-4"
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Category Added Succesfully
                </Alert>
              )}
              <div className=" space-x-4 text-center pb-5">
                {setCategoryDataToServer.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={clearData}>
                      Close
                    </Button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddCategory;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRead from "../../../hooks/useRead";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pricing from "./Pricing";

export const SingleProduct = () => {
  const initialData = {
    pageNo: 0,
    limit: 50,
    searchQuery: "",
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const { paramsObject, data: editProductData } = useRead({
    method: "GET",
    url: "getProductDetails",
    initialData: {
      id: id,
    },
    onSuccess: (res) => {
      let productDetail = res?.product_details;
      //   setProductData(productDetail);
      //   setImagePreview(productDetail?.thumbnail?.image_url);
    },
  });
  const editproductdata = editProductData?.data?.product_details;
  console.log(editproductdata,'edit');

  return (
    <div className="container_xxl relative z-10 px-4">
      <div className="flex items-center space-x-4 headingBorder__b">
        <div>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div>
          <h1 className="heading">Pricing</h1>
        </div>
      </div>
      <div className="flex mt-5 flex-col">
        <div className="flex items-center gap-3">
          <p className="text-xl font-medium">Name:</p>
          <p>{editproductdata?.product_name}</p>
        </div>
        <div className="flex items-center gap-3">
          <p className="text-xl font-medium">Category:</p>
          <p>{editproductdata?.category_name}</p>
        </div>
      </div>
      <Pricing/>
    </div>
  );
};

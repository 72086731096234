import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";

let token = sessionStorage.getItem(TOKEN_KEY);

export const handleLogoutFromServer = async () => {
    let res = await axios({
        url: `${process.env.REACT_APP_API_ENDPOINT}logout`,
        method: "POST",
        data: {},
        headers: { Authorization: token ? `Bearer ${token}` : '', },
    })
    return res
};
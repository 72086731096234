import Moment from "react-moment";
import Spinner from "../../../components/spinner";
import useRead from "../../../hooks/useRead";
import Errors from "../../errors";
import { getOrderPdf } from "../../../apis/orders";
import { useMutation } from "@tanstack/react-query";
import ReactPaginate from "react-paginate";
import { IconButton } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import useCreate from "../../../hooks/useCreate";
import spinner from '../../../assets/images/common/spinner.gif'
import Pagination from "../../../components/elements/pagination";
import { useState } from 'react'

const Orders = () => {

  const initialData = {
    limit: 50,
    pageNo: 0,
    searchQuery: ''
  }
  const limit = 50
  const [currentPage, setCurrentPage] = useState(0)
  const [filterStatus,setFilterStatus] = useState("")
  const { data, paramsObject, setGetListParams } = useRead({method:"GET", initialData, url: 'orderlist' })
  const handleSearchVal = (e) => {
    let value = e.target.value
    let data = {
      ...paramsObject,
      pageNo: 0,
      [e.target.name]: value
    }
    setGetListParams(data)
  }
  const { data: statusList } = useRead({
    method: "GET",
    url: "paymentStatusList"
  })
  const { setDataToServer } = useCreate({
    url: "updatepaymentstatus"
  })
  const orderReportDownload = useMutation((data) => getOrderPdf(data), {
    onSuccess: (data) => {
      let url = window.URL.createObjectURL(data.data);
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: url,
      }).click();
    },
    onError: () => {
    }
  })
  const handleViewOrderPdf = (id) => {
    let data = {
      id: id
    }
    orderReportDownload.mutate(data)
  }
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected
    }
    setGetListParams(data)
  }
  const totalCount = data.data?.total_count
  const statusColors = {
    0: "bg-red-100 text-red-600",
    1: "bg-green-100 text-green-600",
    2: "bg-yellow-100 text-yellow-600",
    3: "bg-red-100 text-red-600",
  }
  const handleChangeStatus = (status_id, order_id) => {
    const newStatus = { order_id, status_id }
    setDataToServer.mutate(newStatus)
  }
  const handleFilterStatus = (e) => {

    let data = {
      ...paramsObject,
      paymentModeFilter:e.target.value
    }
  setFilterStatus(e.target.value)
  setGetListParams(data)
  }
  return (
    <>
      {setDataToServer.isLoading && <div className="fixed w-full h-full  z-50 top-0 left-0 bg-[#ffffff7d]"> <img src={spinner} alt="" className="w-[10rem] h-[10rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /></div>}
      <div>
        <div className='flex items-center justify-between bg-body_bg shadow px-4 py-2'>
          <h2 className='font-semibold text-3xl'>{'Orders'}</h2>
        </div>
        <div className='px-4 py-10'>
          <div className='category_table py-5 space-y-5'>
            <div className='flex items-center space-x-4'>
              <div className='flex-1'>
                <input name='searchQuery' onChange={handleSearchVal} value={paramsObject.searchQuery} type="text" placeholder='Search Order....' className='input' />
              </div>
            </div>
            <div className="w-full flex justify-end text-right items-center px-10 space-x-5">
              <h1>Filter: </h1>
              <div>
                <select value={filterStatus} onChange={(e) => handleFilterStatus(e)} className={`px-4 py-1 rounded capitalize text-sm ` + statusColors[filterStatus]}>
                  {
                    statusList?.data?.data?.map((status) => {
                      const { status_id, payment_status } = status
                      return (
                        <>
                          <option value={status_id} className={statusColors[status_id]}>{payment_status}</option>
                        </>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            {
              data?.data?.data?.length === 0 ?  <h1 className="text-center font-semibold text-2xl">No Data Found</h1> :
              <div>
                 {
              data.isLoading ? <Spinner />
                : data.status === "error" ? <Errors errorObject={data.error} inline /> :
                <>
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                      <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                          <tr className="text-left">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                              Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Customer Name
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Phone
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Order Date
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Total Sum
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                             Order Id
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Order State
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              View
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {data.data?.data?.map((orderData, idx) => {
                            const { order_id,name,phone, total_sum, order_token, order_state, ordered_at, customer_id, status_id,payment_status } = orderData
                            return <tr key={idx}>
                              <td className="border-dashed border-t border-gray-200 userId">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                  {/* {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))} */}
                                  {paramsObject.limit * paramsObject.pageNo + idx + 1}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 text-center firstName">
                                <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                  {name}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 text-center firstName">
                                <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                  {phone}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 text-center lastName">
                                <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                  <Moment date={ordered_at} unix format="lll" />
                                  {/* {ordered_at} */}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 text-center lastName">
                                <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                  &#8377; {total_sum}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 text-center lastName">
                                <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                  {order_id}
                                </span>
                              </td>
                              <td className="border-dashed border-t border-gray-200 w-10 emailAddress "><p className={`border-dashed border-t border-gray-200 text-center py-1 rounded-md ` + statusColors[status_id]}>{payment_status}</p>
                                {/* <div className='flex items-center justify-center'>
                                  <select value={status_id} onChange={(e) => handleChangeStatus(e.target.value, order_id, status_id)} className={`px-4 py-1 rounded capitalize text-sm ` + statusColors[status_id]}>
                                    {
                                      statusList?.data?.data?.map((status) => {
                                        const { status_id, payment_status } = status
                                        return (
                                          <>
                                            <option value={status_id} className={statusColors[status_id]}>{payment_status}</option>
                                          </>
                                        )
                                      })
                                    }
                                  </select>
                                </div> */}
                              </td>
                              <td className="border-dashed border-t border-gray-200 phoneNumber">
                                <div className='flex items-center space-x-5 justify-center'>
                                  <div onClick={() => handleViewOrderPdf(order_id)}>
                                    <IconButton>
                                      <RemoveRedEyeIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className=" my-7">
                      <Pagination
                        currentPage={currentPage}
                        lengthofItems={totalCount}
                        limit={limit}
                        onPageChange={handlePageClick} />
                    </div>
                  </>
            }
              </div>
            }
           
           
          </div>
        </div>
      </div>
    </>
  )
}

export default Orders
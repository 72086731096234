import { Dialog, Slide, Button, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useRef, useState } from "react";
import Spinner from "../../components/spinner";
import MuiAlert from "@mui/material/Alert";
import Editor from "../../components/elements/Editor";
import useCreate from "../../hooks/useCreate";
import toast from "react-hot-toast";
// import { useParams } from "react-router-dom";
import useReadUpdated from "../../hooks/updated/useRead";
import useUploadMedia from "../../hooks/file/useUploadMedia";
import ReactDatePicker from "react-datepicker";
import { formatDate } from "../../utilities";
import useRead from "../../hooks/useRead";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true;

// TODO: Published At to be called properly from backend and uncomment line number 353

function UpdateBlogs({ open, handleClose, id }) {
  const [blogFormData, setBlogFormData] = useState({});
  const handleBlogFormData = (e) => {
    let $this = e.target;

    let data = {
      ...blogFormData,
      [e.target.name]: $this.value,
    };

    setBlogFormData(data);
  };

  const handleBlogSeo = (e) => {
    let value = e.target.value;
    let tempName = e.target.attributes["data-name"].value;
    let data = {
      ...blogFormData,
      seo: {
        ...blogFormData?.seo,
        [tempName]: value,
      },
    };
    setBlogFormData(data);
  };

  const fileInputRef = useRef(null);

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [openSlugModal, setOpenSlugModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [slugCheck, setSlugCheck] = useState();
  const [slug, setSlug] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { data } = useRead({
    url: "edit-blog",
    method: "GET",
    initialData: {
      id,
    },
    onSuccess: (data) => {
      let tempData = JSON.parse(JSON.stringify(data?.data));
      let date = new Date();

      if (
        tempData?.published_at !== null &&
        tempData?.published_at !== undefined
      ) {
        date = new Date(parseInt(tempData?.published_at) * 1000);
      }

      tempData.published_at = date;
      setBlogFormData(tempData);
    },
  });
  const { setDataToServer } = useCreate({
    refreshUrl: "blogsListing",
    url: "update-blog",
    onSuccess: () => {
      setIsSuccess(true);
      toast.success("Blog Edited Successfully", {
        position: "top-right",
        duration: 3000,
      });
      handleClose();
    },
    onError: () => {
      setIsError(true);
      toast.error("Oops! Something went wrong", {
        position: "top-right",
        duration: 3000,
      });
    },
  });
  const { setDataToServer: setEditSlugToServer } = useCreate({
    url: "edit-slug",
    refreshUrl: "blogsListing",
    onSuccess: () => {
      toast.success("Slug Updated Successfully", {
        position: "top-right",
        duration: 3000,
      });
      handleCloseSlugModal();
    },
    onError: (err) => {
      const { status, data } = err.response;
      if (status === 400) {
        toast.error(data?.message, {
          position: "top-right",
          duration: 3000,
        });
      } else {
        toast.error("Something went wrong please try again later", {
          position: "top-right",
          duration: 3000,
        });
      }
    },
  });

  const { upload } = useUploadMedia({
    url: "uploadImage",
    onSuccess: (res) => {
      console.log(res.data);
      
      const { image_url, image_id } = res?.data;
      let formDataCopy = blogFormData;
      formDataCopy["thumbnail"].image_id = image_id;
      setImagePreview(image_url);
      setBlogFormData({ ...blogFormData });
      toast.success("File Uploaded Successfully", {
        position: "top-right",
        duration: 3000,
      });
    },
    onError: () => {
      toast.error("Something went wrong please try again later", {
        position: "top-right",
        duration: 3000,
      });
    },
  });

  const { setDataToServer: setDeleteData } = useCreate({
    url: "delete-blog",
    refreshUrl: "blogsListing",
    onSuccess: () => {
      toast.success("Blog Deleted Successfully", {
        position: "top-right",
        duration: 3000,
      });
      handleClose();
    },
    onError: (err) => {
      console.log(err);
      toast.error("Oops! Something went wrong", {
        position: "top-right",
        duration: 3000,
      });
    },
  });

  const handleSubmitBlogFormData = (e) => {
    e.preventDefault();
    let data = {
      ...blogFormData,
      thumbnail: {
        image_path: blogFormData?.thumbnail?.image_path,
        image_id: blogFormData?.thumbnail?.image_id,
      },
    };
    setDataToServer.mutate(data);
  };

  const handleChangeEditor = (e, value) => {
    let data = {
      ...blogFormData,
      content: value,
    };
    setBlogFormData(data);
  };
  const closeErrorMessage = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  const handleOpenFileUploadDialog = () => {
    fileInputRef.current.click();
  };

  // let uploadScreen;
  const handleUploadFile = (e) => {
    let thumbnail = e.target?.files[0];
    let data = {
      screen: "blogs",
      thumbnail: [thumbnail],
    };
    upload.mutate(data);
  };

  const handleBlogStatus = (e) => {
    let status = e.target.checked;
    let data = {
      ...blogFormData,
      status: status,
    };
    setBlogFormData(data);
    console.log(data);
  };

  const handleCloseSlugModal = () => {
    setOpenSlugModal(false);
    setIsOpen(false);
  };
  const handleOpenSlug = () => {
    setOpenSlugModal(true);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const toggleButton = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  function sanitizetext(intext) {
    const noSpecialChars = intext.replace(/[^a-zA-Z0-9\-]/g, "");
    return noSpecialChars;
  }
  const slugInput = (e) => {
    let val = e.target.value;
    val = val.replace(/\s+/g, "-").toLowerCase();
    let sanval = sanitizetext(val);
    let data = {
      ...blogFormData,
      slug: sanval,
    };
    setBlogFormData(data);
    setSlug({ ...data });
    let senddata = {
      slug: sanval,
    };
  };

  const handleSubmitSlug = (e) => {
    e.preventDefault();
    let data = {
      id: id,
      slug: slug?.slug,
    };
    setEditSlugToServer.mutate(data);
    console.log(data);
  };
  const handleDeleteCategory = (e) => {
    e.preventDefault();
    let data = {
      id,
      status: blogFormData?.status,
    };
    setDeleteData.mutate(data);
  };

  const handleSetPublishDate = (value) => {
    let data = {
      ...blogFormData,
      published_at: value,
    };
    setBlogFormData({ ...data });
  };

  return (
    <>
      <Dialog
        fullScreen
        fullWidth={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <div className="container_xxl px-5">
          <div className="flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white">
            <div>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className="heading">Update Blog</h1>
            </div>
          </div>
          {data?.isLoading ? (
            <>
              {" "}
              <Spinner />
            </>
          ) : (
            <>
              <div className="form-body py-8">
                <form
                  className=""
                  autoComplete="off"
                  onSubmit={handleSubmitBlogFormData}
                >
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label htmlFor="Blog Title" className="label">
                        Blog Title <small className="text-red-700">*</small>
                      </label>
                      <input
                        type="text"
                        required={IS_REQUIRED}
                        placeholder="Enter Blog Title"
                        value={blogFormData?.title}
                        onChange={handleBlogFormData}
                        name="title"
                        className="input mt-4"
                      />
                    </div>
                    <div>
                      <label htmlFor="Blog Description" className="label">
                        Blog Description{" "}
                        <small className="text-red-700">*</small>
                      </label>
                      <input
                        type="text"
                        required={IS_REQUIRED}
                        placeholder="Enter Blog Description"
                        value={blogFormData?.description}
                        onChange={handleBlogFormData}
                        name="description"
                        className="input mt-4"
                      />
                    </div>
                    <div>
                      <label htmlFor="Blog Title" className="label">
                        Blog Publish Date{" "}
                        <small className="text-red-700">*</small>
                      </label>
                      <div className="w-full mt-4">
                        {blogFormData?.published_at && (
                          <>
                            <ReactDatePicker
                              dateFormat="dd/MM/yyyy"
                              required={IS_REQUIRED}
                              selected={blogFormData.published_at}
                              onChange={(date) => handleSetPublishDate(date)}
                              className="input w-full"
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="Blog Title" className="label">
                        Blog Banner Image{" "}
                        <small className="text-red-700">*</small>
                      </label>

                      {upload.isLoading ? (
                        <>
                          <Spinner />
                        </>
                      ) : (
                        <>
                          {
                            <img
                              src={
                                imagePreview
                                  ? imagePreview
                                  : blogFormData?.thumbnail?.image_path
                              }
                              alt="blog image"
                              className="object-contain w-48 h-48 my-4"
                            />
                          }
                        </>
                      )}

                      <div className="mt-4">
                        <Button
                          variant="contained"
                          color="success"
                          className="primaryBtn-contained"
                          onClick={handleOpenFileUploadDialog}
                        >
                          {blogFormData?.thumbnail?.image_path
                            ? " Update Banner Image"
                            : " Add Banner Image"}
                        </Button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="hidden"
                          onChange={(e) => handleUploadFile(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="Blog Title" className="label">
                        Blog Content <small className="text-red-700">*</small>
                      </label>
                      <div className="mt-5">
                        <Editor
                          value={blogFormData?.content}
                          onChange={handleChangeEditor}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="Blog Title" className="label mr-4">
                        Blog Status
                      </label>
                      <span className="text-gray-700 text-sm">Hide</span>
                      <Switch
                        checked={blogFormData?.status}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={(e) => handleBlogStatus(e)}
                        className="hide_show_switch"
                      />
                      <span className="text-gray-700 text-sm">Show</span>
                    </div>
                    <div className="mt-3 flex items-center justify-between border-b border-gray-400">
                      <h1 className="font-semibold text-2xl pb-3">
                        Search Engine Optimization
                      </h1>
                    </div>
                    <div>
                      <div className="mb-2">
                        <label className="label">Meta Title</label>
                        <input
                          type="text"
                          placeholder="Meta Title"
                          name="seo"
                          data-name="title"
                          className="input mt-4"
                          value={blogFormData?.seo?.["title"]}
                          onChange={handleBlogSeo}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="label">Meta Description</label>
                        <input
                          type="text"
                          placeholder="Meta Description"
                          name="seo"
                          data-name="description"
                          className="input mt-4"
                          value={blogFormData?.seo?.["description"]}
                          onChange={handleBlogSeo}
                        />
                      </div>
                    </div>
                    <div className="mt-3 flex items-center justify-between border-b border-gray-400">
                      <h1 className="font-semibold text-2xl pb-3">Slug</h1>
                    </div>
                    <div className="flex space-x-5 mt-4">
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleOpenSlug}
                      >
                        Edit Slug
                      </Button>
                    </div>
                  </div>
                  {isError && (
                    <Alert
                      onClose={closeErrorMessage}
                      className="mb-4"
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {setDataToServer?.error?.message}, Please try again later
                    </Alert>
                  )}
                  {isSuccess && (
                    <Alert
                      onClose={closeErrorMessage}
                      className="mb-4"
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Blog Updated Succesfully
                    </Alert>
                  )}
                  <div className="text-center flex justify-center space-x-5 my-6">
                    {setDataToServer.isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <Button
                          type="submit"
                          variant="contained"
                          className="primaryBtn-contained"
                        >
                          Publish Blog
                        </Button>
                      </>
                    )}
                    <Button
                      type="button"
                      color="error"
                      variant="contained"
                      className="primaryBtn-contained"
                      onClick={handleOpenDeleteModal}
                    >
                      Delete Blog
                    </Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </Dialog>
      <Dialog fullWidth={true} maxWidth={"sm"} open={openSlugModal}>
        <div className="bg-white w-full px-5">
          <div className="modal-head flex items-center justify-end pt-2">
            <div onClick={handleCloseSlugModal}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <form className="space-y-4" onSubmit={handleSubmitSlug}>
            {isOpen ? (
              <>
                <div className="space-y-7">
                  <div>
                    <h1 className="text-2xl font-semibold text-center">
                      Edit Slug
                    </h1>
                  </div>
                  <div className="flex justify-center space-x-5 ">
                    <input
                      type="text"
                      placeholder="Slug Title"
                      className="input"
                      name="slug"
                      value={blogFormData?.slug}
                      onChange={(e) => slugInput(e)}
                    />

                    {setEditSlugToServer.isLoading ? (
                      <Spinner />
                    ) : (
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    )}
                  </div>
                  <div className="text-center">
                    {slugCheck ? (
                      <p
                        class="slugerror text-red-600 text-base mb-6 mt-6 font-semibold"
                        id="slugerror"
                      >
                        Slug already exist
                      </p>
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="flex justify-center pb-5"></div>
                </div>
              </>
            ) : (
              <>
                <div className=" space-y-7">
                  <div>
                    <h1 className=" text-2xl text-red-700 font-semibold text-center">
                      Are You Sure You Want Edit The Slug?
                    </h1>
                    <h3 className="text-lg text-red-700 font-semibold text-center mt-2">
                      This will effect all the back links and SEO
                    </h3>
                  </div>
                </div>
                <div className=" space-x-4 text-center pb-5">
                  <Button
                    type="button"
                    variant="contained"
                    color={`error`}
                    onClick={toggleButton}
                  >
                    Proceed
                  </Button>

                  <Button
                    variant="outlined"
                    color="info"
                    onClick={handleCloseSlugModal}
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </Dialog>
      <Dialog fullWidth={true} maxWidth={"sm"} open={openDeleteModal}>
        <div className="bg-white w-full px-5">
          <div className="modal-head flex items-center justify-end pt-2">
            <div onClick={handleCloseDeleteModal}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <form className="space-y-4" onSubmit={handleDeleteCategory}>
            <div className=" space-y-7">
              <div>
                <h1 className=" text-2xl text-red-700 font-semibold text-center">
                  Are You Sure You Want To Delete?
                </h1>
              </div>
            </div>
            <div className=" space-x-4 text-center pb-5">
              {setDeleteData.isLoading ? (
                <Button type="button">
                  <Spinner />
                </Button>
              ) : (
                <Button type="submit" variant="contained" color={`error`}>
                  Delete
                </Button>
              )}

              <Button
                variant="outlined"
                color="info"
                onClick={handleCloseDeleteModal}
              >
                Close
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}

export default UpdateBlogs;
